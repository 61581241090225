/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
// any CSS you import will output into a single css file (app.css in this case)
import './styles/app_new.scss';
//
import 'chosen-js';
import 'select2/dist/js/select2.full';
// import moment from 'moment';
// global.moment = moment;

import '@popperjs/core';
import * as bootstrap from 'bootstrap';
// import moment from 'moment';
// window.moment = global.moment = moment;
import 'simplebar';

// import '/assets/themes/Hyper/dist/saas/assets/js/vendor.min';
// import moment from 'moment';
// window.moment = global.moment = moment;
import '/assets/themes/Hyper/dist/saas/assets/js/app';

//DATATABLES WITHOUT BS5
// import 'jszip';
// import 'pdfmake';
// import 'datatables.net-dt';
// import 'datatables.net-buttons/js/buttons.colVis';
// import 'datatables.net-buttons/js/buttons.html5';
// import 'datatables.net-buttons/js/buttons.print';
//END - DATATABLES WITHOUT BS5
//DATATABLES BS5
import 'jszip';
import 'pdfmake';
import 'datatables.net';
import 'datatables.net-bs5';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs5';
import 'datatables.net-searchpanes-bs5';
import 'datatables.net-select-bs5';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
//END - DATATABLES BS5
// import '/assets/themes/Hyper/dist/saas/assets/js/vendor/responsive.bootstrap5.min';

import '/assets/themes/Hyper/dist/saas/assets/js/pages/demo.datatable-init';
